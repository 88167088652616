// import axios from 'axios';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from 'axios';
import { sha256 } from 'crypto-hash';
import React, { useEffect, useState } from 'react';
import { addPaymentInfo } from '../../utils/mutations/payments';
import type { Payment, User } from '../../utils/types';

// ! I need to wrap the PayPal button

interface PaymentWidgetProps {
    user: User;
    products: { [productId: string]: number } | null;
    joinCode: string | null;
    onSuccess: (payment: Payment) => void;
}

interface CustomWindow extends Window {
    appendHelcimPayIframe?: (checkoutToken: string, param: boolean) => void;
    removeHelcimPayIframe?: () => void;
}

const PaymentWidget: React.FC<PaymentWidgetProps> = ({ user, products, joinCode, onSuccess }: PaymentWidgetProps) => {
    const [secretToken, setSecretToken] = useState<string | null>(null);
    const [checkoutToken, setCheckoutToken] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [confCode, setConfCode] = useState<string>();

    const customWindow = window as CustomWindow;

    useEffect(() => {
        const confCode = (joinCode?.split('-')[0] || user.conferenceCode);
        // assert that confCode is not empty
        if (!confCode) {
            throw new Error('Conference code is empty!');
        }
        setConfCode(confCode);
    }, [joinCode, user.conferenceCode]);


    const handlePaymentSuccess = async (payment: Payment) => {
        try {
            setLoading(true);
            await addPaymentInfo(payment);

            await axios.post("https://completepayment-2t5cbdn56q-uc.a.run.app", {
                uid: user.uid,
                paymentId: payment.transactionId,
                cart: createCart(joinCode, products),
            });

            onSuccess(payment);
            setLoading(false);

        } catch (error) {
            console.error(error);        
        }        
    }

    // ! TODO: This should probably be moved into the backend (i.e., a function)
    // validate that the payment was processed correctly by checking the hash
    const validatePayment = async (message: any, secretToken: string) => {
        const msg = JSON.parse(message);
        const stringToHash = JSON.stringify(msg.data.data) + secretToken;
        const hash = await sha256(stringToHash);

        if(hash !== msg.data.hash){
            throw new Error('Hashes do not match!');
        }

        return msg.data.data;
    }

    const createCart = (joinCode: string | null, products: { [productId: string]: number } | null) => {
        const cart = [];
    
        if (products) {
            Object.keys(products).forEach((productId) => {
                const quantity = products[productId];
                cart.push({
                    type: "product",
                    item: productId,
                    quantity: quantity,
                });
            });
        }
    
        if (joinCode) {
            cart.push({
                type: "ticket",
                item: joinCode,
                quantity: 1,
            });
        }
        return cart;
    };
    
    
    const setHelcimListener = () => {
        // set a listener for the payment modal
        window.addEventListener('message', (event) => {
            const helcimPayJsIdentifierKey = 'helcim-pay-js-' + checkoutToken;
  
            if(event.data.eventName === helcimPayJsIdentifierKey){
                
                if(event.data.eventStatus === 'ABORTED'){
                    console.error('Transaction failed!', event.data.eventMessage);
                }
                
                if(event.data.eventStatus === 'SUCCESS' && secretToken){
                    validatePayment(event.data.eventMessage, secretToken)
                    .then((data) => {
                        const payment : Payment = {
                            type: data.type,
                            amount: data.amount,
                            transactionId: data.transactionId,
                            item: joinCode || 'store purchase',
                            uid: user.uid,
                            dateCreated: data.dateCreated,
                            customerCode: data.customerCode,
                        }
                        if(customWindow.removeHelcimPayIframe){
                            customWindow.removeHelcimPayIframe();
                        } else {
                            console.error('removeHelcimPayIframe is not available on window');
                        }
                        handlePaymentSuccess(payment);
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                }
            }
        });
    }

    // validate the join code and get the checkout token
    const initializeHelcimPayment = async () => {
        setLoading(true);
        axios.post('https://initializehelcim-2t5cbdn56q-uc.a.run.app', {
            cart: createCart(joinCode, products),
            confCode: confCode,
        })
        .then((response) => {
            if (response.data.secretToken && response.data.checkoutToken) {
                // set the secret token and checkout token
                setSecretToken(response.data.secretToken);
                setCheckoutToken(response.data.checkoutToken);
            }
        })
        .catch((err) => {
            console.error(err);
        })
    }

    // open the payment modal when the checkout token is available
    useEffect(() => {
        // open the payment modal
        if (checkoutToken) {
            setHelcimListener();
            if (customWindow.appendHelcimPayIframe) {
                customWindow.appendHelcimPayIframe(checkoutToken, true);
            } else {
                console.error('appendHelcimPayIframe is not available on window');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutToken]);

    const initializePayPalPayment = async (_: any, __: any) => {
        try {
            const response = await axios.post("https://initializepaypal-2t5cbdn56q-uc.a.run.app", {
                email: user.email,
                cart: createCart(joinCode, products),
                confCode: confCode,
            });

            if (response.data.id) {
                return response.data.id;
            } else {
                const errorDetail = response.data.details?.[0];
                const errorMessage = errorDetail
                    ? `${errorDetail.issue} ${errorDetail.description} (${response.data.debug_id})`
                    : JSON.stringify(response);
                throw new Error(errorMessage);
            }
        } catch (err) {
            console.error(err);
            throw new Error('Failed to initialize PayPal payment');
        }
    };    

    // check Approval
    const onApprove = (_: any, actions: any) => {
        return actions.order.capture().then(async (details: any) => {
            const payment : Payment = {
                amount: details.purchase_units[0].amount.value,
                item: joinCode || 'store purchase',
                type: 'PayPal',
                customerCode: details.payer.payer_id,
                transactionId: details.purchase_units[0].payments.captures[0].id,
                dateCreated: new Date(),
                uid: user.uid,
            }            
            handlePaymentSuccess(payment);
        });
    };

    // capture likely error
    const onError: (err: any, actions?: any) => void = (data, actions) => {
        console.error(data, actions);
        console.error('An error occurred during the PayPal payment process.');
    };

    const PAYPAL_API_KEY = "ARsdr9xZ_YIcxR7_-xbr5zZVYEodzlUtyZH-BQP_IoV5PU5bMbsD3Y1zXVlv9D7QHjezx86E7hfc_25X"
    // const PAYPAL_API_KEY = "Ad1TLtgzVXT63b98Sf0sdwSTTF7o11uFw_jLplNEQNYdzVlyGC6-liUaPy2D85Aie2WKxr-BrzNCBmFn";

    
    // Auto close the loading screen after 3 seconds
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [loading]);

    return (
        <>
          <Backdrop
            open={loading}
            onClick={() => setLoading(false)}
            sx={{
              color: '#fff',
              zIndex: (theme) => theme.zIndex.drawer + 1,
              position: 'fixed', 
              top: 0, 
              left: 0,
              width: '100vw',
              height: '100vh',
            }}
          >
            <CircularProgress 

                            sx={{
                                color: 'white',
                            }} />
          </Backdrop>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "8px" }}>
            <Button
              variant="contained"
              onClick={initializeHelcimPayment}
              style={{ marginTop: "8px", color: "black", backgroundColor: "white", borderRadius: "4px" }}
              startIcon={<CreditCardIcon />}
            >
              Pay with Credit Card
            </Button>
            <PayPalScriptProvider options={{ "client-id": PAYPAL_API_KEY }}>
              <PayPalButtons
                style={{ layout: "vertical" }}
                createOrder={initializePayPalPayment}
                onApprove={onApprove}
                onError={onError}
                fundingSource={"paypal"}
              />
            </PayPalScriptProvider>
          </Box>
        </>
      );
};

export default PaymentWidget;
