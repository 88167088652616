import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { dark_red } from "../../../utils/colors";
import { getLocations } from "../../../utils/mutations/schedule";
import type { Location, User } from "../../../utils/types";
import { useLocation } from "react-router-dom";
import Footer from "../menuBars/Footer";
import MenuBar from "../menuBars/MenuBar";
import { GoogleMap, MarkerF, InfoWindowF, useJsApiLoader } from '@react-google-maps/api';

interface MapProps {
    user: User;
    confType: 'main' | 'hyls';
    isSmallScreen: boolean;
}

export default function MapPage({ user, confType, isSmallScreen }: MapProps) {
    const center = { lat:  42.373678, lng: -71.116405 };

    const [locations, setLocations] = useState<Location[]>([]);
    const [fetching, setFetching] = useState<boolean>(true);
    const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);

    useEffect(() => {
        const fetchLocations = async () => {
            if (!user.conferenceCode) return;
            try {
                const locationsData = await getLocations(user.conferenceCode);
                setLocations(locationsData);
                setFetching(false);
            } catch (error) {
                console.error("Error fetching locations:", error);
                // Handle error, display message, etc.
            }
        };
        fetchLocations();
    }, [user.conferenceCode]);

    const location = useLocation();
    useEffect(() => {
        const locationState: Location = location?.state?.location as Location;
        
        if (locationState) {
            setSelectedLocation(locationState);
        }
    }, [location?.state?.location]);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDeX3qc3Rx_otux7Lkw0-9s0DCit80oQjg",
    });

    const handleMarkerClick = (location: Location) => {
        setSelectedLocation(location);
    };

    return (
        <Box>
            <MenuBar user={user} confType={confType} isSmallScreen={isSmallScreen} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px',
                    marginTop: '64px',
                    minHeight: 'calc(100vh - 64px - 130px)'
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 'bold', color: dark_red }}>
                    Site Map
                </Typography>
                {isLoaded && !fetching && (
                    <Box sx={{ padding: '20px' }}>
                        <GoogleMap
                            mapContainerStyle={{ width: '50vw', height: '75vh' }}
                            center={center}
                            zoom={15}
                        >
                            {locations.map((location) => (
                                <MarkerF
                                    key={location.name}
                                    position={{ lat: location.lat, lng: location.lng }}
                                    onClick={() => handleMarkerClick(location)}
                                />
                            ))}
                            {selectedLocation && (
                                <InfoWindowF
                                    position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
                                    onCloseClick={() => setSelectedLocation(null)}
                                >
                                    <Box>
                                        <Typography variant="body1" sx={{ color: dark_red }}>
                                            {selectedLocation.name}
                                        </Typography>
                                    </Box>
                                </InfoWindowF>
                            )}
                        </GoogleMap>
                    </Box>
                )}
            </Box>
            <Footer />
        </Box>
    );
}
