import { Unsubscribe } from 'firebase/firestore';
import { db } from '../firebase';
import { Day, Event, Location } from '../types';
import { arrayUnion } from 'firebase/firestore';

/*
-------------------------------------------------------------------------------------
    Schedule Object Structure
-------------------------------------------------------------------------------------
    [
        {
            id: "[uid]"
            name: "Day 1",
            date: "8/20",
            events: [
                {
                    id: "[uid]",
                    name: "Opening Ceremony",
                    start: "8:00 am",
                    end: "9:00 am",
                    location: {
                        name: "Main Hall",
                        lat: 0,
                        lng: 0,
                    } as Location,
                    link: "https://zoom.com/123",
                    speaker: "Cole Harten",
                    speakerTitle: "CEO of Harten Industries",
                    assignedIDs: ["uid1", "uid2", ...],
                    scannedIDs: ["uid1", "uid2", ...], // scannedIDs are a subset of assignedIDs
                },
                ...
            ]
        },
        ...
    ]
*/

export function subscribeToSchedule(confCode: string, callback: (data: Day[]) => void): Unsubscribe {
    const scheduleRef = db.collection("conferences").doc(confCode).collection("days");
    const unsubscribe = scheduleRef.onSnapshot(async (snapshot) => {
        const schedule: Day[] = await Promise.all(snapshot.docs.map(async (doc) => {
            const dayData = doc.data();
            const eventsSnapshot = await db.collection(`conferences/${confCode}/days/${doc.id}/events`).get();
            const events: Event[] = eventsSnapshot.docs.map((eventDoc) => {
                const eventData = eventDoc.data();
                return {
                    id: eventDoc.id,
                    name: eventData.name,
                    start: eventData.start,
                    end: eventData.end,
                    location: eventData.location || null,
                    link: eventData.link || null,
                    speaker: eventData.speaker || null,
                    speakerTitle: eventData.speakerTitle || null,
                    assignedIDs: eventData.assignedIDs || [],
                    scannedIDs: eventData.scannedIDs || [],
                };
            })
            // Sort events by start time
            .sort((a, b) => {
                const padTime = (time: string) => {
                    let [hours, minutes] = time.split(':');
                    hours = hours.padStart(2, '0'); // Ensure two digits for hours
                    return `${hours}:${minutes}`;
                };
                return padTime(a.start).localeCompare(padTime(b.start));
            });

            return {
                id: doc.id,
                date: dayData.date,
                events: events || [],
            };
        }));   
        
        // Sort days by date
        const sortedSchedule = schedule.sort((a, b) => {
            if (!a.date || !b.date) return 0;

            const aDateParts = a.date.split("/");
            const bDateParts = b.date.split("/");
            const aMonth = parseInt(aDateParts[0]);
            const bMonth = parseInt(bDateParts[0]);
            const aDay = parseInt(aDateParts[1]);
            const bDay = parseInt(bDateParts[1]);

            // Compare months first
            if (aMonth !== bMonth) {
                return aMonth - bMonth;
            }

            // If months are the same, compare days
            return aDay - bDay;
        });

        callback(sortedSchedule);
    });
    return unsubscribe;
}

export async function updateDay(confCode: string, day: Day): Promise<void> {
    try {
        const dayRef = db.doc(`conferences/${confCode}/days/${day.id}`);
        
        // Start a batch to perform multiple operations
        const batch = db.batch();

        // Update the day document
        batch.set(dayRef, {
            date: day.date,
        });

        // Get the reference to the events collection
        const eventsRef = db.collection(`conferences/${confCode}/days/${day.id}/events`);

        // Fetch existing events
        const existingEventsSnapshot = await eventsRef.get();
        
        // Delete events not present in the updated day
        existingEventsSnapshot.forEach(doc => {
            if (!day.events.some(updatedEvent => updatedEvent.id === doc.id)) {
                batch.delete(doc.ref);
            }
        });

        // Update or add events in the updated day
        day.events.forEach(event => {
            const eventRef = eventsRef.doc(event.id);
            batch.set(eventRef, {
                name: event.name,
                start: event.start,
                end: event.end,
                location: event.location || null,
                link: event.link || null,
                speaker: event.speaker || null,
                speakerTitle: event.speakerTitle || null,
                assignedIDs: event.assignedIDs || [],
            });
        });

        // Commit the batch
        await batch.commit();
    } catch (error) {
        console.error("Error updating day:", error);
        throw error;
    }
}


export async function deleteDay(confCode: string, dayId: string): Promise<void> {
    try {
        const dayRef = db.doc(`conferences/${confCode}/days/${dayId}`);
        await dayRef.delete();
    }
    catch (error) {
        console.error("Error deleting day:", error);
        throw error;
    }
}

export async function getLocations(confCode: string): Promise<Location[]> {
    try {
        const daysRef = await db.collection(`conferences/${confCode}/days`).get();
        const locations: Location[] = [];

        for (const dayDoc of daysRef.docs) {
            const eventsRef = await db.collection(`conferences/${confCode}/days/${dayDoc.id}/events`).get();
            for (const eventDoc of eventsRef.docs) {
                const eventData = eventDoc.data();
                if (eventData.location) {
                    locations.push(eventData.location);
                }
            }
        }

        return locations;
    } catch (error) {
        console.error("Error getting locations:", error);
        throw error;
    }
}

export async function scanID(confCode: string, dayId: string, eventId: string, uid: string): Promise<void> {
    try {
        const eventRef = db.doc(`conferences/${confCode}/days/${dayId}/events/${eventId}`);
        const eventDoc = await eventRef.get();
        if (!eventDoc.exists) {
            console.error("Event not found");
        }

        const event = eventDoc.data() as Event;
        if (!event.assignedIDs.includes(uid)) {
            console.error("User not assigned to event");
        }

        // Do an array union to add the scanned ID to the list
        await eventRef.update({
            scannedIDs: arrayUnion(uid)
        });
    } catch (error) {
        console.error("Error scanning ID:", error);
        throw error;
    }
}
