import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';

import { Payment } from '../types';

// mutation to add payment info to new payments collection 
export async function addPaymentInfo(payment : Payment) : Promise<void> {
   try{
      await setDoc(doc(db, 'payments', payment.transactionId), {
         type: payment.type,
         amount: payment.amount,
         uid: payment.uid,
         dateCreated: payment.dateCreated.valueOf(),
         item: payment.item,
         customerCode: payment.customerCode,
      });
   } catch (error) {
      console.error(error);
   } 
} 

export async function getPaymentInfo(transactionId: string): Promise<Payment | null> {
   try {
        const paymentRef = doc(db, 'payments', transactionId);
        const paymentDoc = await getDoc(paymentRef);
        if (paymentDoc.exists()) {
            const payment: Payment = {
                type: paymentDoc.data().type ?? "N/A",
                amount: paymentDoc.data().amount,
                transactionId: transactionId,
                uid: paymentDoc.data().uid,
                dateCreated: paymentDoc.data().dateCreated ? new Date(paymentDoc.data().dateCreated) : new Date(paymentDoc.data().paymentTime.seconds * 1000),  //TODO: Get rid of this once all the dates are correctly stored as unix timestamps
                item: paymentDoc.data().item || paymentDoc.data().joinCode,
                customerCode: paymentDoc.data().customerCode ?? 'N/A',
            };
            return payment;
        }
       
       return null;
   } catch (error) {
       console.error(error);
       return null;
   }
}
