import { Box, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { getConferenceCodes } from '../../../utils/mutations/conferences';
import { subscribeToUsers, updateUserConfCode } from '../../../utils/mutations/users';
import { User } from '../../../utils/types';
import TableWidget from '../../widgets/TableWidget';
import Footer from '../adminMenuBar/AdminFooter';
import MenuBar from '../adminMenuBar/AdminMenuBar';


interface AdminUsersProps {
  user: User;
}

export default function AdminUsers({user}: AdminUsersProps) {
  const [users, setUsers] = useState<User[]>([]);
  const [conferenceCodes, setConferenceCodes] = useState<string[]>([]);

  useEffect(() => {
    const unsubscribe = subscribeToUsers((data : User[]) => {
      setUsers(data);
    });

    // Fetch conference codes
    const fetchConfCodes = async () => {
      const conferenceCodes = await getConferenceCodes();
      setConferenceCodes(conferenceCodes);
    }

    fetchConfCodes();

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

    const handleChangeConferenceCode = async (uid: string, newConfCode: string, confCode: string | null) => {
      if (prompt("Please enter your password to confirm changes:") !== "admin123") return;
      await updateUserConfCode(uid, newConfCode, confCode);
    };


    const rows = users.map(registrant => [
      registrant.displayName,
      registrant.email,
      registrant.uid,
      user.accessLevel === 1 ? (
        <Select
          key={registrant.uid}
          value={registrant.conferenceCode || ''}
          onChange={(event) => handleChangeConferenceCode(registrant.uid, event.target.value as string, registrant.conferenceCode)}
          MenuProps={{ PaperProps: { style: { maxHeight: '80px' } } }}
          sx={{
            padding: '6px 8px',
            height: '20px',
            width: '160px'
          }}
        >
          {conferenceCodes.map(code => (
            <MenuItem key={code} value={code}>
              {code}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography key={user.uid} sx={{
          textAlign: 'center'
        }}>
          {registrant.conferenceCode || '-'}
        </Typography>
      )
    ]);
    

  const headers = ['Name', 'Email', 'UID', 'Conference'];

  return (
    <Box sx={{
      minHeight: 'calc(100vh - 64px - 110px)'
    }}>
      <MenuBar />
      <Box sx={{ marginTop: '64px', p: '10px 30px' }}>
        {/* <AdminUsersTable users={users} searchQuery={searchQuery} confCodes={conferenceCodes}/> */}
        <TableWidget rows={rows} headers={headers} search={true} />
      </Box>
      <Footer />
    </Box>
  );
}
