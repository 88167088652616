import { AddCircleOutline, Close, MoreHoriz } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { GoogleMap, InfoWindowF, MarkerF, StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Event, Location } from '../../../../utils/types';
import CustomInput from '../../../widgets/CustomInput';

const libraries: any = ['places'];

interface EventModalProps {
  onClose?: () => void;
  onSubmit: (data: Event) => void;
  onDelete?: (id: string) => void;
  event?: Event;
  locations: Location[];
}

const EventModal: React.FC<EventModalProps> = ({ onClose, onSubmit, onDelete, event, locations }) => {
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm<Event>();

  const [open, setOpen] = useState<boolean>(false);

  const [markerPosition, setMarkerPosition] = useState<{ lat: number; lng: number } | null>(null);
  const [newLocationName, setNewLocationName] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<string>(event?.location?.name ?? ''); // State to store selected location
  const [activeMarker, setActiveMarker] = useState<string|null>(null);

  useEffect(() => {
    if (event) {
      setSelectedLocation(event.location?.name ?? '');
      setMarkerPosition(event.location?.lat && event.location?.lng ? { lat: event.location.lat, lng: event.location.lng } : null);
    }
  }, [open, event]);

  const generateUUID = () => {
    const length = 20;
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let randomString = '';

    for (let i = 0; i < length; i++) {
        randomString += characters.charAt(array[i] % charactersLength);
    }

    return randomString;
  }

  // Function to close the modal
  const handleClose = () => {
    reset();
    setOpen(false);
    onClose?.();
    setMarkerPosition(null);
    setSelectedLocation('');
    setNewLocationName(null);
  }; 
  
  const handleFormSubmit = (data: Event) => {
    if(data.location?.name === '') {
      data.location = null;
    }
    if(data.link === '') {
      data.link = null;
    }

    // Throw an error if both location and link are neither null nor empty strings
    if(data.location && data.link) {
      alert('Please select either a location or a link, not both');
      return;
    }

    /*
    * If event is provided and has been saved, update it with the new data
    */
    let e: Event;
    if (event && event.id){
      e = {...data, id: event.id, assignedIDs: event.assignedIDs ?? []};
    } else {
      const id = generateUUID();
      e = {...data, id: id, assignedIDs: event?.assignedIDs ?? []};
    }
   
    onSubmit(e);
    handleClose();
  };

  const handleDelete = (id: string) => {
    onDelete?.(id);
    handleClose();
  }

  // Map related state and handlers
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  const center = { lat: 13.74352, lng: 100.52737 };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setMarkerPosition({ lat, lng });
      setValue('location.lat', lat);
      setValue('location.lng', lng);
    }
  };

  // Handler for selecting a location from the dropdown
  const handleLocationSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(event.target.value === '') {
      setMarkerPosition(null);
      setSelectedLocation('');
      setValue('location.lat', 0);
      setValue('location.lng', 0);
      setValue('location.name', "");
    } else{
      setSelectedLocation(event.target.value);
      const selectedLocationObj = locations.find(loc => loc.name === event.target.value);
      if (selectedLocationObj) {
        setMarkerPosition({ lat: selectedLocationObj.lat, lng: selectedLocationObj.lng });
        setValue('location.lat', selectedLocationObj.lat);
        setValue('location.lng', selectedLocationObj.lng);
        setValue('location.name', selectedLocationObj.name);
      }
    }
  };

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const location = place.geometry?.location;
      if (location) {
        const lat = location.lat();
        const lng = location.lng();
        setMarkerPosition({ lat, lng });
        setValue('location.lat', lat);
        setValue('location.lng', lng);
      }
    }
  };

  const inputProps = {
    '&:before': {
      borderBottom: '1px solid black',
    },
    '&:after': {
      borderBottom: '2px solid black',
    },
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDeX3qc3Rx_otux7Lkw0-9s0DCit80oQjg",
    libraries: libraries,
  });

  const formatCoordinate = (lat: number, lng: number) => {
    const latAbs = Math.abs(lat);
    const lngAbs = Math.abs(lng);
    const latDirection = lat >= 0 ? 'N' : 'S';
    const lngDirection = lng >= 0 ? 'E' : 'W';
    
    return `${latAbs.toFixed(4)}° ${latDirection}, ${lngAbs.toFixed(4)}° ${lngDirection}`;
  };
  
  return (
    <>
      {event ? <IconButton onClick={()=>{setOpen(true)}}><MoreHoriz /></IconButton> : <Button startIcon={<AddCircleOutline />} onClick={() => setOpen(true)}>Add Event</Button>}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <form onSubmit={handleSubmit(handleFormSubmit)} onKeyDown={(e: any)=>{if(e.key === "Enter"){e.preventDefault()}}}>
        <DialogTitle>
          {event ? 'Edit Event' : 'Create Event'} {/* Change dialog title based on whether event is provided */}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', gap: '20px' }} >
            <Box  sx={{ display: 'flex', flexDirection: 'column', gap: "20px" }} >
              <CustomInput 
                label="Event Name" 
                id="name"
                type="text"
                sx={inputProps}
                defaultValue={event?.name}
                register={register('name', { required: 'Event name is required' })} 
                errorText={errors.name?.message}
              />
              <CustomInput 
                label="Start Time (hh:mm)" 
                id="start"
                type="text"
                sx={inputProps}
                defaultValue={event?.start}
                register={register('start', { 
                  required: 'Start time is required',
                  pattern: {
                    value: /^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/,
                    message: 'Invalid start time format'
                  }
                })} 
                errorText={errors.start?.message}
              />
              <CustomInput 
                label="End Time (hh:mm)" 
                id="end"
                type="text"
                defaultValue={event?.end}
                sx={inputProps}
                register={register('end', { 
                  required: 'End time is required',
                  pattern: {
                    value: /^([0-9]|[01][0-9]|2[0-3]):[0-5][0-9]$/,
                    message: 'Invalid end time format'
                  }}
                )}
                
                errorText={errors.end?.message}
              />
              <Typography variant="body1" sx={{ color: 'black', marginBottom: '0.25rem', fontWeight: 'bold' }}
                >Location Name</Typography>
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                }}>
                <TextField
                  select
                  value={selectedLocation}
                  onChange={handleLocationSelect}
                  defaultValue={event?.location?.name ?? ''}
                  variant="standard"
                  sx={{ width: '300px' }}
                >
                  <MenuItem value="">None</MenuItem>
                  {locations.map((loc) => (
                    <MenuItem key={loc.name} value={loc.name}>
                      {loc.name}
                    </MenuItem>
                  ))}
                  {newLocationName && <MenuItem value={newLocationName}>{newLocationName}</MenuItem>}
                </TextField>  
                <IconButton onClick={()=>{
                    const newLocation = prompt("Enter new location name");
                    if(newLocation) {
                      setNewLocationName(newLocation);
                      setSelectedLocation(newLocation);
                      setValue('location.name', newLocation);
                      setMarkerPosition(null);
                    }
                  }}><AddCircleOutline /></IconButton>
              </Box>
              <CustomInput
                label="Link"
                id='link'
                type="text"
                defaultValue={event?.link}
                sx={inputProps}
                register={register('link', {
                  pattern: {
                    value: /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/,
                    message: "Please enter a valid URL",
                  },
                })}
                errorText={errors.link?.message}
              />
              <CustomInput 
                label="Speaker" 
                id="speaker"
                type="text"
                defaultValue={event?.speaker}
                sx={inputProps}
                register={register('speaker')} 
                errorText={errors.speaker?.message}
              />
              <CustomInput 
                label="Speaker Title" 
                id="speakerTitle"
                type="text"
                defaultValue={event?.speakerTitle}
                sx={inputProps}
                register={register('speakerTitle')} 
                errorText={errors.speakerTitle?.message}
              />
            </Box>
            {isLoaded && (
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                <StandaloneSearchBox
                  onLoad={ref => (searchBoxRef.current = ref)}
                  onPlacesChanged={handlePlacesChanged}
                >
                  <TextField
                    type="text"
                    variant='standard'
                    placeholder="Search for places"
                    sx={{
                      textOverflow: 'ellipsis',
                      width: '300px',
                    }}
                  />
                </StandaloneSearchBox>
                <GoogleMap
                  mapContainerStyle={{ width: '100%', height: '50vh' }}
                  center={center}
                  zoom={12}
                  onClick={handleMapClick}
                >
                  {markerPosition && <MarkerF 
                                      position={markerPosition}
                                      draggable={true} // Enable dragging
                                      onDragEnd={(event) => {
                                        // Update marker position when dragging ends
                                        const lat = event.latLng?.lat() ?? markerPosition.lat;
                                        const lng = event.latLng?.lng() ?? markerPosition.lng;
                                        setMarkerPosition({ lat, lng });
                                        setValue('location.lat', lat);
                                        setValue('location.lng', lng);
                                      }}/>
                  } 
                  {
                  // Display markers for all locations
                  locations
                  .filter((loc) => !isNaN(loc.lat) && !isNaN(loc.lng)) // Filter out locations with invalid coordinates
                  .map((loc)=>{
                    return (
                      <MarkerF 
                          key={loc.name}
                          position={{ lat: loc.lat, lng: loc.lng }}
                          icon={{
                              url: 'https://maps.google.com/mapfiles/ms/icons/blue-dot.png',
                              scaledSize: new google.maps.Size(30, 30),
                          }}
                          onClick={() => {
                            if (loc.name === activeMarker) {
                                return;
                            }
                            setActiveMarker(loc.name);
                        }}
                      >
                          {activeMarker === loc.name && (
                              <InfoWindowF
                                  position={{ lat: loc.lat, lng: loc.lng }}
                                  onCloseClick={() => setActiveMarker(null)}
                              >
                                  <Box>{loc.name}</Box>
                              </InfoWindowF>
                          )}
                      </MarkerF>
                    )
                  })                      
                                      
                  }
                </GoogleMap>
                {markerPosition && 
                  <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '10px', alignItems: 'center'}}>
                    <Button variant="text" sx={{justifySelf: 'flex-end'}} onClick={()=>{
                      // reset with event coords
                      setMarkerPosition({ lat: event?.location?.lat ?? 0, lng: event?.location?.lng ?? 0 });
                    }}>Reset</Button>
                    <Typography variant="body1">
                      {formatCoordinate(markerPosition.lat, markerPosition.lng)}
                    </Typography>
                  </Box>
                }
              </Box>
            )}

          </Box>
        </DialogContent>
        <DialogActions>
          {event && onDelete && <Button onClick={() => handleDelete(event?.id)} color="error">Delete</Button>}
          <Button type="submit" color="primary">{event ? "Save" : "Create"}</Button>
        </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default EventModal;
