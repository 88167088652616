import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, InputAdornment, Tooltip, MenuItem, Select, Chip, Input, ListItemText, FormControlLabel } from "@mui/material";
import { Close as CloseIcon, People as PeopleIcon, Search as SearchIcon, Check as CheckIcon } from '@mui/icons-material';
import { Day, Event, User } from "../../../../utils/types";

interface AddUserModalProps {
    users: User[];
    day: Day;
    event: Event;
    onClose: (day: Day, event: Event, assignedUsers: string[] | null) => void;
}

const AddUserModal = ({ day, event, users, onClose }: AddUserModalProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = useState<Set<User>>(new Set());
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filterTicketClasses, setFilterTicketClasses] = useState<string[]>([]);

    // Extract unique ticket classes from the users array
    const uniqueTicketClasses: string[] = Array.from(new Set(users.map(user => user.ticketClass))).filter((ticketClass): ticketClass is string => ticketClass !== null);

    useEffect(() => {
        if (!open) return;
        // Set selected users to those already assigned to the event
        setSelectedUsers(new Set(users.filter(user => event.assignedIDs.includes(user.uid))));
    }, [open, event, users]);

    const handleToggle = (user: User) => {
        setSelectedUsers(prevSelectedUsers => {
            const updatedSelectedUsers = new Set(prevSelectedUsers);
            if (updatedSelectedUsers.has(user)) {
                updatedSelectedUsers.delete(user);
            } else {
                updatedSelectedUsers.add(user);
            }
            return updatedSelectedUsers;
        });
    };

    const handleClose = () => {
        // if unsaved changes, prompt user to save
        if (selectedUsers.size !== event.assignedIDs.length) {
            if (!window.confirm('You have unsaved changes. Are you sure you want to close?')) return;
        }

        setOpen(false);
        onClose(day, event, null);
    };

    const handleSave = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        onClose(day, event, Array.from(selectedUsers).map(user => user.uid));
        setOpen(false);
    };

    const handleChangeFilterClass = (event: any) => {
        const {
            target: { value },
        } = event;
        const selectedClasses = typeof value === 'string' ? value.split(',') : value;
        setFilterTicketClasses(selectedClasses);
    };

    const filteredUsers = new Set(
        users.filter(user =>
            (filterTicketClasses.length === 0 || (user.ticketClass && filterTicketClasses.includes(user.ticketClass))) &&
            user.displayName.toLowerCase().includes(searchQuery.toLowerCase())
        )
    );

    const toggleFiltered = () => {
        const allFilteredSelected = Array.from(filteredUsers).every(user => selectedUsers.has(user));
        setSelectedUsers(prevSelectedUsers => {
            const updatedSelectedUsers = new Set(prevSelectedUsers);
            if (allFilteredSelected) {
                Array.from(filteredUsers).forEach(user => updatedSelectedUsers.delete(user));
            } else {
                Array.from(filteredUsers).forEach(user => updatedSelectedUsers.add(user));
            }
            return updatedSelectedUsers;
        });
    };

    return (
        <>
            <IconButton onClick={() => setOpen(true)}>
                <PeopleIcon />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        width: '600px',
                        maxWidth: '100%',
                    },
                }}
            >
                <form onSubmit={handleSave}>
                    <DialogTitle>
                        Select Users {selectedUsers.size > 0 && `(${event.scannedIDs?.length ?? 0}/${selectedUsers.size})`}
                        <IconButton
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                justifyContent: 'flex-start',
                                alignItems: 'flex-start',
                            }}
                        >
                            {/* Row box for class filter and select */}
                            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '25px', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Typography variant="body2">Filter by ticket class</Typography>
                                    <Select
                                        variant="standard"
                                        value={filterTicketClasses}
                                        onChange={handleChangeFilterClass}
                                        size="small"
                                        displayEmpty
                                        multiple
                                        input={<Input />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.sort().map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {uniqueTicketClasses.map((ticketClass: string) => (
                                            <MenuItem key={ticketClass} value={ticketClass}>
                                                <Checkbox checked={filterTicketClasses.includes(ticketClass)} />
                                                <ListItemText primary={ticketClass} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <Tooltip title="Select or deselect all registrants listed below, leaving rest unchanged.">
                                        <Button variant="outlined" onClick={toggleFiltered} sx={{color:'black', outlineColor: 'black'}}>Toggle Filtered</Button>
                                    </Tooltip>
                                </Box>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <Typography variant="body2">Search for user</Typography>
                                <TextField
                                    variant="standard"
                                    size="small"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{ width: '100%' }}
                                />
                            </Box>

                            {Array.from(filteredUsers)
                                .sort((a, b) => a.displayName.localeCompare(b.displayName))
                                .map((user) => (
                                    <Box key={user.uid} sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedUsers.has(user)}
                                                    onChange={() => handleToggle(user)}
                                                />
                                            }
                                            label={user.displayName}
                                        />
                                        {event.scannedIDs?.includes(user.uid) && <CheckIcon fontSize="small" />}
                                    </Box>
                                ))}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
};

export default AddUserModal;
